import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Cabecalho extends Component {

    constructor(props){
        super(props);
    }

    render() {
        
        console.log(this.props.revenda);

        return (
            <nav className="b-nav">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 col-xs-4">
                            <Link to="/" className="wow slideInLeft logo_top" >
                                <img src="/img/logo.png" alt={ " Logotipo "+ this.props.revenda.penome_fantasia } style={{ marginBottom: "-10px" }} />
                            </Link>
                        </div>
                        <div className="col-sm-9 col-xs-8">
                            <div className="b-nav__list wow slideInRight" data-wow-delay="0.3s">
                                <div className="navbar-header">
                                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#nav">
                                        <span className="sr-only">Toggle navigation</span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                    </button>
                                </div>
                                <div className="collapse navbar-collapse navbar-main-slide" id="nav">
                                    <ul className="navbar-nav-menu">
                                        <li><Link to="/" > Início</Link></li>
                                        <li><Link to="/venda-seu-veiculo" > Venda seu veículo</Link></li>
                                        <li><Link to="/financiamento" > Financiamento</Link></li>
                                        <li><Link to="/contato" > Contato</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }

}

export default Cabecalho