const Rebase   = require('re-base');
const firebase = require('firebase');

var FirebaseConfig = {
    apiKey: "AIzaSyDU7Y0AEbymFQ2bC2H4CXcugCnPm4cLOeg",
    authDomain: "intermedio-1513801146022.firebaseapp.com",
    databaseURL: "https://intermedio-1513801146022.firebaseio.com",
    projectId: "intermedio-1513801146022",
    storageBucket: "intermedio-1513801146022.appspot.com",
    messagingSenderId: "534908779124"
};

const app       = firebase.initializeApp(FirebaseConfig);
const config    = Rebase.createClass(app.database());

export const storage    = app.storage();
export const firestore  = app.firestore();

export default config;