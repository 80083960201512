import React, { Component } from 'react';
import Cabecalho from '../componentes/Cabecalho';
import Rodape from '../componentes/Rodape';
import Atendimento from '../componentes/Atendimento';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import { id_firebase } from '../content-site';
import { firestore } from './../firebase-config';

class Contato extends Component {

    constructor (props){
        super(props);

        this.state = {
            assunto:'',
            nome:'',
            email: '',
            telefone: '',
            mensagem: '',
            enviado: false,
            msg_enviado: '',
            revenda: {},
        }
        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount(){

        window.scrollTo(0, 0);

        this.mounted = true;

        firestore.collection("revenda").doc(id_firebase)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    revenda: doc.data(),
                });
            }
        }).catch(function(error) {
        });
    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        event.preventDefault();
        
        this.setState({enviado:true, msg_enviado: 'Enviando mensagem...'});

        var mensagem = "Nome: "+ this.state.nome + "<br>E-mail: "+ this.state.email + "<br>Telefone: "+ this.state.telefone + "<br>Mensagem: "+ this.state.mensagem;

        fetch('https://us-central1-intermedio-1513801146022.cloudfunctions.net/enviarEmail', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                remetente: "Contato " + this.state.revenda.penome_fantasia + "<" + this.state.revenda.peemail + ">",
                assunto: this.state.assunto,
                destinatarios: this.state.revenda.peemail,
                corpo: mensagem,
                corpoHtml: mensagem
            })
        })
        .then(data => {
            this.setState({
                enviado:true, 
                msg_enviado: 'E-Mail Enviado com Sucesso',
                nome: "", 
                assunto: "", 
                email: "", 
                telefone: "", 
                mensagem: "", 
            });
            setInterval(() => this.setState({enviado:false}), 5000);
        })
        .catch(error => {
            this.setState({enviado:true, msg_enviado: 'Erro ao enviar e-mail, tentar novamente mais tarde'});
            setInterval(() => this.setState({enviado:false}), 5000);
        });
         
    }

    render(){
        return(
            <div>
                <Cabecalho revenda={this.state.revenda} />
                <div>
                    <section className="b-contacts s-shadow">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-6">
                                    <div className="b-contacts__form ">
                                        <header className="b-contacts__form-header s-lineDownLeft wow zoomInUp" data-wow-delay="0.5s">
                                            <h2 className="s-titleDet">Entre em contato conosco</h2> 
                                        </header>
                                        <p className=" wow zoomInUp" data-wow-delay="0.5s">Envie dúvidas, sugestões, comentários, etc. Em breve nossa equipe retornará o contato.</p>
                                        <ValidatorForm id="contactForm" className="s-form wow zoomInUp" ref="form" onSubmit={this.handleSubmit} >
                                            <TextValidator
                                                label="ASSUNTO *"
                                                onChange={this.handleChange}
                                                name="assunto"
                                                value={this.state.assunto}
                                                className="form-ui-bootstrap"
                                                validators={['required']}
                                                errorMessages={['Preenchimento obrigatório']}
                                            />
                                            <TextValidator
                                                label="NOME *"
                                                onChange={this.handleChange}
                                                name="nome"
                                                value={this.state.nome}
                                                className="form-ui-bootstrap"
                                                validators={['required']}
                                                errorMessages={['Preenchimento obrigatório']}
                                            />
                                            <TextValidator
                                                label="E-MAIL *"
                                                onChange={this.handleChange}
                                                name="email"
                                                value={this.state.email}
                                                className="form-ui-bootstrap"
                                                validators={['required', 'isEmail']}
                                                errorMessages={['Preenchimento obrigatório', 'E-mail inválido']}
                                            />
                                            <InputMask
                                                mask="(99) 99999-9999"
                                                value={this.state.telefone}
                                                label="TELEFONE *"
                                                name="telefone"
                                                onChange={this.handleChange}
                                            >
                                                {() => <TextField
                                                label="TELEFONE *"
                                                name="telefone"
                                                type="text"
                                                className="form-ui-bootstrap"
                                                />}
                                            </InputMask> 
                                            <br/>
                                            <br/>
                                            <textarea name="mensagem" placeholder="MENSAGEM" onChange={this.handleChange} ></textarea>
                                            <button type="submit" className="btn m-btn">ENVIAR MENSAGEM<span className="fa fa-angle-right"></span></button>
                                            {
                                                this.state.enviado &&
                                                <blockquote>
                                                    <p><em>{ this.state.msg_enviado }</em></p>
                                                </blockquote>
                                            }
                                        </ValidatorForm>
                                    </div>
                                </div>
                                <Atendimento revenda={this.state.revenda} />
                            </div>
                        </div>
                    </section>

                </div>
                <Rodape revenda={this.state.revenda} />
            </div>
        )
    }
}

export default Contato